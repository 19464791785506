import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <div className='tabs' style={{textAlign:"center"}}>
       <img src='./quizz_eggs_namish.png' width="200" height="100" style={{userSelect:"none"}}></img>
     </div>
     <div className='hero'>
       <h1 className='inf'>Make quizzes that are interesting as well as challenging</h1>
       <div style={{display:"flex", justifyContent:"center", textAlign:"center"}}>
       <form onSubmit={function(e) {
         e.preventDefault();
         forTeachers();
       }}>
       <button type='submit'>For Teachers</button>
       </form>
       <form onSubmit={function(e) {
         e.preventDefault();
         forStudents();
       }}>
       <button type='submit'>For Students</button>
       </form>
       </div>
     </div>
  </React.StrictMode>
);
function forTeachers() {

}
function forStudents() {
   
}

